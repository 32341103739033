

  .bm-burger-button{
    display: none;
}
  /* Color/shape of burger icon bars */
  /* .bm-burger-bars {
    background: url("../../assets/batman.jpg")
  } */
  
  /* Color/shape of burger icon bars on hover*/
  /* .bm-burger-bars-hover {
    background: #fff !important;
  } */
  
  /* Position and sizing of clickable cross button */
  /* .bm-cross-button {
    height: 54px;
    width: 54px;
  } */
  
  /* Color/shape of close button cross */
  .bm-cross {
    background: #bdc3c7;
  }
  
  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
      display: none;
    position: fixed;
    /* height: 1000%; */
  }
  
  /* General sidebar styles */
  .bm-menu {
      display: none;
    /* background: #373a47;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em; */
  }
  
  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }
  
  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
  }
  
  /* Individual item */
  .bm-item {
    display: flex ;
  }
  
  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }
  
  @media(max-width:800px){
      .bm-burger-button{
          display: block;
          position: fixed;
         width: 40px;
         height: 40px;
         right: 15px;
          bottom: 20px;
          background-color: #fff;
          border-radius: 0.3rem;
          border: 3px solid #03353E;
          z-index: 20 !important;
      }
      .bm-menu-wrap {
        display: block;
      position: fixed;
      height: max-content !important;
      width: 280px !important;
    }
    .bm-menu {
        display: block;
        /* position: fixed; */
        /* top: 20px; */
      background: #03353E;
      /* padding: 2.5em 1.5em 0; */
      font-size: 1.15em;
      height: max-content;
      border-radius: 1rem;
    }
    
  }