.post-img-holder{
    margin: auto;
    width: 80%;
    height: 250px;
    border-radius: 5px;
    /* margin-top: 1rem; */
  }
  .post-img{
    width: 80%;
    height: 250px;
    object-fit: cover;
  }
  #input[type="file"] {
      display: block;
  }

  @media(max-width:500px){
    .post-img-holder{
      width: 250px;
      height: 150px;
    }
    .post-img{
      width: 250px;
      height: 150px;
    }
  }