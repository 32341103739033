.inputStyle {
	width: 3rem !important;
	height: 3rem;
	margin: 0 1rem;
	font-size: 1.5rem;
	border-radius: 4px;
	border: 1px solid rgba(0, 0, 0, 0.3);
  }
  @media (max-width: 450px) {
    .inputStyle {
        width: 2.5rem !important;
        height: 3rem;
        margin: 0 1rem;
      }
}