.img-holder{
    margin: auto;
    width: 150px;
    height: 150px;
    border-radius: 5px;
    margin-top: 1rem;
  }
  .img{
    width: 150px;
    height: 150px;
    object-fit: cover;
  }
  #input[type="file"] {
      display: block;
  }